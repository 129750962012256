<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BRANCH DIRECTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3 v-if="is_can_view">
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              @change="selected_category"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-form>
      <v-data-table dense :headers="headers" :items="active_items" :search="search" class="mt-5">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.branch_code }}
            </td>
            <td>
              {{ item.address }}
            </td>
            <td>
              {{ item.branch_manager!=null?item.branch_manager.last_name + ',' +
              item.branch_manager.first_name:
              (item.chapel_manager!=null?item.chapel_manager.last_name + ',' +
              item.chapel_manager.first_name:'')}}
            </td>
            <td>
              {{ item.branch_manager!=null?item.branch_manager.contact_no:
              (item.chapel_manager!=null?item.chapel_manager.contact_no:'')}}
            </td>
            <td>
              {{ item.branch_staff!=null?item.branch_staff.last_name + ',' +
              item.branch_staff.first_name:
              (item.chapel_staff!=null?item.chapel_staff.last_name + ',' +
              item.chapel_staff.first_name:'')}}
            </td>
            <td>
              {{ item.branch_staff!=null?item.branch_staff.contact_no:
              (item.chapel_staff!=null?item.chapel_staff.contact_no:'')}}
            </td>
            <td>
              <v-icon
                v-if="position==='ADMIN'||position==='HUMAN RESOURCE'"
                class="mr-2"
                color="info"
                @click="edit_branch_address(item)"
              >
                {{icons.mdiPencil}}
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_can_edit" persistent max-width="80%">
      <v-card>
        <v-form ref="form" class="multi-col-validation">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">EDIT BRANCH ADDRESS</h4>
            </v-toolbar-title>
          </v-toolbar>

          <v-layout row wrap class="align-center mx-2 mt-5">
            <v-flex xs12 md12 class="mt-4">
              <v-text-field
                class="mx-2"
                v-model="branch_address"
                dense
                label="Branch Address"
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"
              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                class="w-full"
                color="primary"
                @click="done_edit"
                v-if="!saving_data"
              >
                Done
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-btn color="error" @click="is_can_edit = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPencil} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      is_can_view: false,
      is_can_edit: false,
      branch_address: '',
      selected_data: {},

      category_id: '',
      category_items: [],
      active_items: [],
      deactive_items: [],
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiCheck},
        {title: 'DEACTIVATED', icon: mdiClose},
      ],
      tin_no: '',
      email_address: '',
      contact_no: '',
      address: '',
      branch_code: '',
      city_id: '',
      city_items: [],
      province_id: '',
      province_items: [],
      region_id: '',
      region_items: [],
      row: null,
      search: '',
      headers: [
        {text: 'Branch Code', value: 'branch_code', sortable: false},
        {text: 'Address', value: 'address', sortable: false},
        {text: 'Branch Manager', value: 'address', sortable: false},
        {text: 'Contact # BM', value: 'address', sortable: false},
        {text: 'Branch Staff', value: 'address', sortable: false},
        {text: 'Contact # BS', value: 'address', sortable: false},
        {text: 'Action', value: 'address', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPencil,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'employee_category_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['initialize_branch', 'branch_data', 'update_branch_address']),
      edit_branch_address(item) {
        this.is_can_edit = true
        this.selected_data = item
        this.branch_address = item.address
      },
      done_edit() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var cat = this.category_id
          const data = new FormData()
          data.append('id', this.selected_data.id);
          data.append('new_address', this.branch_address.toUpperCase());
          this.update_branch_address(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
              this.category_id = cat
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      initialize_data() {
        this.initialize_branch()
          .then(response => {
            this.category_items = response.data[0].category
            this.region_items = response.data[0].region
            this.category_id = this.employee_category_id
            this.is_can_view = (this.employee_category_id === 1 || this.employee_category_id === 2)
            this.selected_category()
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {

        const data = new FormData()
        data.append('category_id', this.category_id);
        this.branch_data(data)
          .then(response => {
            this.active_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
